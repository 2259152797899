<template>
  <div class="">
    <b-card>
      <h3>教材</h3>
      <hr>
      <div
        v-if="material.length === 0"
        class=""
      >
        ダウンロードできる教材はありません。
      </div>
      <div
        v-else
        class=""
      >
        <div
          v-for="(item, index) in material"
          :key="index"
          class="mb-1"
        >
          <a
            :href="item.path"
            target="_blank"
          >
            {{ item.name }}
          </a>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BCard,
} from 'bootstrap-vue'
import { ref, onMounted } from '@vue/composition-api'
import useLesson from '@/components/js/compositionAPI/lesson/useLesson'
import { useRouter } from '@core/utils/utils'

export default {
  components: {
    BCard,
  },
  directives: {
    Ripple,
  },
  props: {
  },
  setup() {
    const isUserAuthrized = ref(false)
    const lessonId = ref(0)
    const { route } = useRouter()
    const {
      categoryA,
      isAuthrized,
    } = useLesson()
    const material = ref([])
    const listA = [
      { name: 'Lesson1テキスト.pdf', path: 'https://firebasestorage.googleapis.com/v0/b/be-creation-salon.appspot.com/o/material%2Ftouchcare%2FLesson1%E3%83%86%E3%82%AD%E3%82%B9%E3%83%88.pdf?alt=media&token=431d18d0-cc95-4d3a-86e9-edd798041a3b' },
      { name: 'Lesson2テキスト.pdf', path: 'https://firebasestorage.googleapis.com/v0/b/be-creation-salon.appspot.com/o/material%2Ftouchcare%2FLesson2%E3%83%86%E3%82%AD%E3%82%B9%E3%83%88.pdf?alt=media&token=415b1475-dce3-4ae4-8df5-e4ebeafdc5d9' },
      { name: 'Lesson3テキスト.pdf', path: 'https://firebasestorage.googleapis.com/v0/b/be-creation-salon.appspot.com/o/material%2Ftouchcare%2FLesson3%E3%83%86%E3%82%AD%E3%82%B9%E3%83%88.pdf?alt=media&token=b5b4ae8e-5c92-4dec-8405-2eafe93a36bf' },
      { name: 'Lesson4テキスト.pdf', path: 'https://firebasestorage.googleapis.com/v0/b/be-creation-salon.appspot.com/o/material%2Ftouchcare%2FLesson4%E3%83%86%E3%82%AD%E3%82%B9%E3%83%88.pdf?alt=media&token=a4090974-ed3c-4f74-a5f7-b00bbde47b33' },
      { name: 'Lesson5テキスト.pdf', path: 'https://firebasestorage.googleapis.com/v0/b/be-creation-salon.appspot.com/o/material%2Ftouchcare%2FLesson5%E3%83%86%E3%82%AD%E3%82%B9%E3%83%88.pdf?alt=media&token=48090ecf-7059-4076-8598-6ea026c27ca3' },
    ]
    onMounted(async () => {
      const category = categoryA.find(v => v.id === Number(route.value.query.id))
      isUserAuthrized.value = await isAuthrized({ lessonId: category.id })
      lessonId.value = Number(route.value.query.id)
      if (lessonId.value === 2953) material.value = listA
    })
    return {
      lessonId,
      material,
    }
  },
}
</script>

<style scoped>
.videoSpace {
  width: 100%;
  margin: 0 auto 0 auto;
}
</style>
