<template>
  <div class="space-basic">
    <div class="mb-2">
      <b-breadcrumb
        :items="breadcrumbItems"
      />
    </div>
    <h2>動画視聴</h2>
    <b-alert
      variant="primary"
      show
    >
      <div class="alert-body mt-1 mb-2">
        <div class="d-flex">
          <b-avatar
            size="40"
            src="https://firebasestorage.googleapis.com/v0/b/protex-b9e4c.appspot.com/o/brand%2Fwoman-intro.png?alt=media&token=998c4413-ebc2-46cc-a62d-16c0565dfe32"
            variant="light-primary"
            class="mr-50"
          />
          <div class="">
            ご購入済みの動画を視聴できます。
          </div>
        </div>
      </div>
    </b-alert>
    <b-tabs align="right">
      <b-tab>
        <template #title>
          <feather-icon icon="VideoIcon" />
          <span class="iconSmartPhone">メイン講座</span>
        </template>
        <Video />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="BookIcon" />
          <span class="iconSmartPhone">教材</span>
        </template>
        <Material />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BBreadcrumb, BAlert, BAvatar, BTabs, BTab,
} from 'bootstrap-vue'
import Video from '@/components/student/lesson/component/Video.vue'
import Material from '@/components/student/lesson/component/Material.vue'
import { ref } from '@vue/composition-api'

export default {
  components: {
    BBreadcrumb,
    BAlert,
    BAvatar,
    BTabs,
    BTab,
    Video,
    Material,
  },
  directives: {
    Ripple,
  },
  props: {
    dataDic: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    const breadcrumbItems = ref([
      {
        text: 'ダッシュボード',
        to: { name: 'student-dashboad' },
        icon: 'HomeIcon',
      },
      {
        text: '動画視聴',
        active: true,
        key: 'subTitle',
      },
    ])
    return {
      breadcrumbItems,
    }
  },
}
</script>

<style scoped>
.videoSpace {
  width: 100%;
  margin: 0 auto 0 auto;
}
</style>
