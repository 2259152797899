<template>
  <DetailLesson />
</template>

<script>
import DetailLesson from '@/components/student/lesson/DetailLesson.vue'

export default {
  components: {
    DetailLesson,
  },
}
</script>
