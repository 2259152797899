<template>
  <div class="">
    <b-card
      :img-src="picSrc"
      img-height="250px"
    >
      <h2 class="mb-2">
        {{ title }}
      </h2>
      <div class="mb-2">
        {{ description }}
      </div>
      <hr>
      <div
        v-if="!isUserAuthrized"
        class="mt-2"
      >
        <b-alert
          variant="danger"
          show
        >
          <div class="alert-body mt-1 mb-2">
            {{ errorMSG }}
          </div>
        </b-alert>
      </div>
      <div v-else>
        <div
          v-for="(item, index) in lessonList"
          :key="index"
          class="mb-1"
        >
          <div class="d-flex justify-content-between">
            <div class="">
              {{ item.lessonName }}
            </div>
            <div class="text-rights">
              <b-button
                variant="text-primary"
                size="sm"
                class="btn-wishlist"
                @click="showVideo(item.lessonURI)"
              >
                <feather-icon
                  icon="PlayCircleIcon"
                  class="mr-50"
                />
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </b-card>
    <b-modal
      v-model="isShowModal"
      size="lg"
      ok-only
      ok-title="閉じる"
      centered
      @ok="refetchEvents"
    >
      <iframe
        :src="movieURI"
        width="640"
        height="400"
        frameborder="0"
        allow="autoplay; fullscreen"
        allowfullscreen
        class="videoSpace"
      />
    </b-modal>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BCard, BButton, BAlert,
} from 'bootstrap-vue'
import { ref, onMounted } from '@vue/composition-api'
import useLesson from '@/components/js/compositionAPI/lesson/useLesson'
import { useRouter } from '@core/utils/utils'

export default {
  components: {
    BCard,
    BButton,
    BAlert,
  },
  directives: {
    Ripple,
  },
  props: {
    dataDic: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    const title = ref('')
    const description = ref('')
    const movieURI = ref('')
    const lessonList = ref([])
    const isShowModal = ref(false)
    const isUserAuthrized = ref(false)
    const errorMSG = ref('')
    const picSrc = ref('')

    const { route } = useRouter()
    const {
      categoryA,
      getCategoryListsScoped,
      isAuthrized,
    } = useLesson()
    onMounted(async () => {
      const category = categoryA.find(v => v.id === Number(route.value.query.id))
      title.value = category.labelName
      description.value = category.description
      picSrc.value = category.picURI
      const ida = category.value
      isUserAuthrized.value = await isAuthrized({ lessonId: category.id })
      if (!isUserAuthrized.value) {
        errorMSG.value = '権限がないため、動画視聴ができません。'
        return
      }
      const d = {
        categoryA: String(ida),
        categoryB: String(1),
      }
      lessonList.value = await getCategoryListsScoped(d)
    })

    const showVideo = item => {
      isShowModal.value = true
      const uri = item.split('/')
      movieURI.value = `https://player.vimeo.com/video/${uri[3]}?h=${uri[4]}`
    }
    const refetchEvents = () => {
      isShowModal.value = false
    }

    return {
      isShowModal,
      title,
      description,
      movieURI,
      lessonList,
      showVideo,
      refetchEvents,
      errorMSG,
      isUserAuthrized,
      picSrc,
    }
  },
}
</script>

<style scoped>
.videoSpace {
  width: 100%;
  margin: 0 auto 0 auto;
}
</style>
